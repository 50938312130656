import { useState, useEffect, useCallback } from "react";
import env from "react-dotenv";
import Select from 'react-select';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useSearchParams, Outlet } from "react-router-dom";

function LayoutManagement() {

  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const auth = useAuth0();

  const langs = [
    {value: 'it', label: 'it'},
    {value: 'en', label: 'gb'},
  ];

  const loadUser = useCallback(async (force) => {
    if (auth.isAuthenticated) {
      setLoading(true);
      if (force || !window.sessionStorage.getItem('userdata')) {
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        window.sessionStorage.setItem('userdata', JSON.stringify(await fetch(env.AUTH0_API_SERVER+"user", {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json())));
      }
      const userData = JSON.parse(window.sessionStorage.getItem('userdata'));
      setPermissions(userData.permissions);
      setLoading(false);
    }
  }, [auth]);

  const login = async () => {
    await auth.loginWithPopup({authorizationParams: {connection: "Username-Password-Authentication"}});
    await loadUser(true);
  };

  const loginPasswordless = async () => {
    await auth.loginWithPopup({authorizationParams: {connection: "email"}});
    await loadUser(true);
  };

  const logout = async () => {
    await auth.logout({logoutParams: {returnTo: window.location.origin}})
    window.sessionStorage.removeItem('store');
    window.sessionStorage.removeItem('userdata');
  };

  useEffect(() => {
    if (searchParams.get('redirect')) {
      window.location.href = searchParams.get('redirect');
    } else {
      loadUser(false);
      if (window.localStorage.getItem('lang')) {
        i18n.changeLanguage(window.localStorage.getItem('lang'));
      } else {
        window.localStorage.setItem('lang', i18n.language)
      }
    }
  }, [searchParams, loadUser, i18n])

  return (
    <section id="layout">
      <header className="header">
        <nav className="navbar navbar-expand">
          <div className="container-fluid">
            <div className="navbar-brand">
              {auth.isAuthenticated && <button className="btn auth-button" onClick={() => logout()}>{t('logout')}</button>}
            </div>
            <div className="justify-content-end" id="navbarContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Select className="nav-link" defaultValue={langs.find((x) => x.value === i18n.language)} options={langs} formatOptionLabel={(x) => {return (<span className={'fi fi-'+x.label}></span>)}} onChange={(x) => {window.localStorage.setItem('lang', x.value); i18n.changeLanguage(x.value);}} />
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" data-bs-toggle="tooltip" data-bs-placement="left" title="Home Page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                      <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="main container">
        {!loading && <div className="text-center">
          <div className="affiliation-title-outer">
            <h1 className="affiliation-title">Moonycard</h1>
          </div>
          {!auth.isAuthenticated && <div className="affiliation-section">
            <div className="mb-5">
              <button className="btn auth-button" onClick={() => login()}>{t('login_password')}</button>
            </div>
            <div className="mb-5">
              <button className="btn auth-button" onClick={() => loginPasswordless()}>{t('login_otp')}</button>
            </div>
          </div>}
          {auth.isAuthenticated && <Outlet context={[permissions]} />}
        </div>}
        {loading && <div className="mt-5 d-flex align-items-center justify-content-center">
          <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>
        </div>}
      </main>
      <footer className="footer">
        <div className="text-center mt-5">
          <div className=" mb-5">
            <span>&#169; Moonycard</span>
          </div>
        </div>
      </footer>

    </section>
  );
}

export default LayoutManagement;

import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";

function Home() {

  const { t } = useTranslation();

  const auth = useAuth0();

  const [store, role] = useOutletContext();

  const changePassword = async () => {
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const response = await fetch(env.AUTH0_API_SERVER+"change-password", {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        client_id: env.AUTH0_CLIENT,
      }),
    }).then((res) => res.json());
    window.open(response.ticket, '_blank');
  };

  return (
    <section id="home">
      {(role === 'admin' || role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('active_services')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
            </tr>
          </thead>
          {store?.services && Object.values(store.services).length > 0 && <tbody>
            {JSON.parse(window.sessionStorage.getItem('services')).filter((s) => !!store.services[s.code]).map((v, k) => (
              <tr key={k}>
                <td>
                  <h3><a className="item-button-link" href={'/'+v.code}>{t(v.code)}</a></h3>
                </td>
              </tr>
            ))}
          </tbody>}
        </table>
      </div>}
      {(role === 'admin' || role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('settings')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3>{t('store')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/store">{t('manage')}</a>
              </td>
            </tr>
            {role === 'admin' && <tr>
              <td>
                <h3>{t('notifications')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/notifications">{t('manage')}</a>
              </td>
            </tr>}
            {role === 'admin' && <tr>
              <td>
                <h3>{t('services')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/services">{t('manage')}</a>
              </td>
            </tr>}
            {false && role === 'admin' && <tr>
              <td>
                <h3>{t('users')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/users">{t('manage')}</a>
              </td>
            </tr>}
          </tbody>
        </table>
      </div>}
      <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('profile')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('email')}</td>
              <td>{JSON.parse(window.sessionStorage.getItem('userdata'))?.email}</td>
            </tr>
            <tr>
              <td>{t('password')}</td>
              <td><button className="btn btn-sm item-button" onClick={() => changePassword()}>{t('change_password')}</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Home;

import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

function Store() {

  const [name, setName] = useState('');
  const [address, setAddress] = useState({});
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [site, setSite] = useState('');
  const [updating, setUpdating] = useState(false);
  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validSite, setValidSite] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const [store, role, updateStore] = useOutletContext();

  const update = async () => {
    setLoading(true);
    setValidPhone(true);
    setValidEmail(true);
    setValidSite(true);
    if (phone !== '' && !/[+]{0,1}[0-9]+/.test(phone)) {
      setValidPhone(false);
    } else if (email !== '' && !/\S+@\S+\.\S+/.test(email)) {
      setValidEmail(false);
    } else if (site !== '' && !/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(site)) {
      setValidSite(false);
    } else {
      setUpdating(false);
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      const localStore = await fetch(env.AUTH0_API_SERVER+"configure", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          store: window.sessionStorage.getItem('store'),
          name: name,
          address: address,
          phone: phone,
          email: email,
          site: site,
        }),
      }).then((res) => res.json());
      updateStore(localStore);
    }
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store') && (role === 'admin' || role === 'user')) {
        setLoading(true);
        setName(store.name);
        setAddress(store.address ?? {});
        setPhone(store.phone ?? '');
        setEmail(store.email ?? '');
        setSite(store.site ?? '');
        setLoading(false);
      }
    };
    initData();
  }, [store, role])

  return (
    <section id="home">
      {!loading && (role === 'admin' || role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('store')}</h2>
        {updating && <button className="btn item-button-success" onClick={ () => update() } disabled={!name || !address?.formatted_address} data-bs-toggle="tooltip" data-bs-placement="right" title={t('confirm')}>{t('confirm')}</button>}
        {updating && <button className="btn item-button" onClick={ () => setUpdating(false) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('cancel')}>{t('cancel')}</button>}
        {!updating && role === 'admin' && <button className="btn item-button" onClick={ () => setUpdating(true) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>{t('update')}</button>}
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('name')}</p>
              </td>
              <td>
                {updating && <input className="affiliation-input" value={name} onChange={(e) => setName(e.target.value)} />}
                {!updating && <span>{store.name}</span>}
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('address')}</p>
              </td>
              <td>
                {updating && <Autocomplete className="affiliation-input" defaultValue={address?.formatted_address} apiKey={env.GMAP_KEY} language="it" onPlaceSelected={(place) => setAddress(place)} options={{types: []}} />}
                {!updating && <span>{store.address?.formatted_address}</span>}
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('phone')}</p>
              </td>
              <td>
                {updating && <input className="affiliation-input" value={phone} onChange={(e) => setPhone(e.target.value)} />}
                {updating && !validPhone && <p><small>{t('valid_phone')}</small></p>}
                {!updating && <span>{store.phone}</span>}
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('email')}</p>
              </td>
              <td>
                {updating && <input className="affiliation-input" value={email} onChange={(e) => setEmail(e.target.value)} />}
                {updating && !validEmail && <p><small>{t('valid_email')}</small></p>}
                {!updating && <span>{store.email}</span>}
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('site')}</p>
              </td>
              <td>
                {updating && <input className="affiliation-input" value={site} onChange={(e) => setSite(e.target.value)} />}
                {updating && !validSite && <p><small>{t('valid_url')}</small></p>}
                {!updating && <span>{store.site}</span>}
              </td>
            </tr>
          </tbody>
        </table>
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Store;
